.container {
    margin: 1rem;
    display: flex;
    width: 70px;
    flex-direction: column;
    align-items: center;
    font-size: small;
    cursor: pointer;
}

.image {
    width: 64px;
    height: 64px;
}
.text {
    word-wrap: normal;
    text-align: center;
}
