.resizeClickZone {
    /* background-color: red; */
    height: 2rem;
    width: 2rem;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1000;
    cursor: se-resize;
    user-select: none;
}

.onTop {
    z-index: 100;
}
