.radio {
    padding-left: 10rem;
    display: block;
}

.baseContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}

.resultContainer {
    margin-top: 2rem;
    border: solid black 1px;
    min-height: 100px;
    flex: 1;
    margin-bottom: 7rem;
    overflow-y: scroll;
}

.table {
    width: 100%;
    font-size: small;
    text-align: left;
    border-spacing: 0;
}
.tableHead {
    background-color: buttonface;
    
    
}

.tableHead>tr > th  {
    border: solid black 1px;
}

.table > tbody > tr:hover {
    background-color: buttonface;
    cursor: pointer;
}
