#page-content {
    height: 100vh;
    overflow: hidden;
}

.title-bar {
    user-select: none;
}
html {
    overflow: hidden;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}
